<router-outlet></router-outlet>
@if (user.proxying()) {
	<div class="absolute bottom-[calc(5vw-50px)] left-[5vw] z-30 flex w-[90vw] items-center gap-4 rounded-b-md border-t-4 border-ss-green-900 bg-ss-green-400 p-4">
		<mat-icon class="row-span-2 grow-0 text-ss-green-900">info_outline</mat-icon>
		<div class="grow">
			<div>
				You are currently proxying admin access for a facility. Any changes
				you make will be as if the admin of the facility made the change.
			</div>
			<div class="cursor-pointer font-semibold underline" (click)="user.changeProxyAccess()">
				Click here if you need to stop proxying admin access.
			</div>
		</div>
	</div>
}
<div class="absolute bottom-2 right-2 h-4 w-4" id="2fa-captcha"></div>
