import { enableProdMode, importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { provideRouter, Route, withComponentInputBinding, withEnabledBlockingInitialNavigation } from '@angular/router';
import { MatChipsModule } from '@angular/material/chips';
import { provideAnimations } from '@angular/platform-browser/animations';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { setPersistence } from '@firebase/auth';
import { browserSessionPersistence, getAuth, provideAuth } from '@angular/fire/auth';
import { firebaseConfig } from '@ss/typings/config';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { TokenInterceptor } from '@ss/shared/firebase-utils';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MAT_RIPPLE_GLOBAL_OPTIONS, MatNativeDateModule, RippleGlobalOptions } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ntaMainGuard } from '@ss/nta-hunter/common';
import { MatSnackBar } from '@angular/material/snack-bar';

const globalRippleConfig: RippleGlobalOptions = {
	disabled: true,
	animation: {
		enterDuration: 0,
		exitDuration: 0,
	},
};
const routes: Route[] = [
	{
		path: 'account',
		loadChildren: () => import('@ss/nta-hunter/account').then((m) => m.ACCOUNT_ROUTES),
	},
	{
		path: '',
		loadChildren: () => import('@ss/nta-hunter/core').then((m) => m.CORE_ROUTES),
		canActivate: [ntaMainGuard]
	},
	{ path: '**', redirectTo: 'facility' },
];

if (environment.production) {
	enableProdMode();
}

bootstrapApplication(AppComponent, {
	providers: [
		importProvidersFrom(BrowserModule, provideFirebaseApp(() => initializeApp(firebaseConfig)), provideAuth(() => {
			const auth = getAuth();
			setPersistence(auth, browserSessionPersistence);
			return auth;
		}), provideFirestore(() => getFirestore()), provideStorage(() => getStorage()), provideAnalytics(() => {
			const analytics = getAnalytics();

			return analytics;
		}), provideDatabase(() => getDatabase()), MatChipsModule, MatNativeDateModule, MatIconModule),
		{ provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
		{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
		MatIconRegistry,
		MatSnackBar,
		provideAnimations(),
		provideHttpClient(withInterceptorsFromDi()),
		provideRouter(
			routes,
			withEnabledBlockingInitialNavigation(),
			withComponentInputBinding()
		),
		importProvidersFrom(MatDialogModule),
		ScreenTrackingService,
		UserTrackingService
	]
})
	.catch((err) => console.error(err));
