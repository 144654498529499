import { Component, HostListener, inject, OnInit } from '@angular/core';
import { InactivityService, RouteCheckService } from '@ss/nta-hunter/common';
import { UserStore } from '@ss/simpliscreen/facility';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { filter, startWith } from 'rxjs';

@Component({
	selector: 'nta-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	standalone: true,
	imports: [
		RouterOutlet,
		NgIf,
		MatIconModule,
	],
})
export class AppComponent {

	inactivityStore = inject(InactivityService);

	user = inject(UserStore);

	@HostListener('window:click')
	handleClick() {
		this.inactivityStore.handleInactiveCheck();
	}

	@HostListener('window:mousemove')
	handleMouseMove() {
		this.inactivityStore.handleInactiveCheck();
	}
}
